export function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();
        return;
    }
    document.addEventListener('DOMContentLoaded', fn);
}

export function handleFieldsEnabledByCheckbox(checkboxHtmlItem, fieldHtmlItem) {
    if (!checkboxHtmlItem || !fieldHtmlItem) {return;}

    fieldHtmlItem.disabled = !checkboxHtmlItem.checked;

    checkboxHtmlItem.addEventListener('change', event => {
        fieldHtmlItem.disabled = !checkboxHtmlItem.checked;
    });
}
